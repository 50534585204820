import React, {useState} from "react";
import Seo from "../components/seo"
import Layout, {BlackLink, SegmentLink, StyledLink} from "../components/layout";
import {Link, navigate} from "gatsby";
import {FiX} from 'react-icons/fi';
import {useCart} from '../cart';

import {CompactButton} from "../components/button";
import {useIsMobile} from "../utils";

const Cart = () => {
    const {isMobile} = useIsMobile();
    const {checkout, removeItem, checkoutId} = useCart();
    const items = checkout?.items || [];
    return (
        <div>
            <Seo title="購物車"/>
            <div id="checkout_html"/>
            <div
                style={{
                    display: "flex",
                    maxWidth: 800,
                    margin: '0px auto',
                    justifyContent: 'center',
                    alignItems: 'start',
                    ...(isMobile ? {
                        flexDirection: 'column'
                    } : {}),
                }}
            >

                <div style={{
                    display: "flex",
                    flex: 3,
                    flexDirection: "column",
                }}>
                    {
                        (items.length < 1  || !checkout )&& (
                            <div>
                                <h5>
                                    購物車中沒有商品
                                </h5>
                                <Link to="/shop">
                                    前往商店
                                </Link>
                            </div>
                        )
                    }
                    {
                        items.map((e) => <div key={e.id} style={{
                            margin: '1rem',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                        >
                            <FiX
                                style={{
                                    color: 'red',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    removeItem(e.id);
                                }}></FiX>
                            <div style={{ width: 32 }} />
                            <div
                                onClick={() => {
                                    navigate(e.url);
                                }}
                            >
                                <img
                                    style={{
                                        width: 100,
                                        height: 100,
                                        cursor: 'pointer',
                                    }}
                                    src={e.imageUrl}/>
                            </div>
                            <div
                                style={{
                                    width: 24,
                                }}
                            >

                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <div
                                    onClick={() => {
                                        navigate(e.url);
                                    }}
                                    style={{
                                    fontWeight: 'bold',
                                    cursor: 'pointer',
                                }}>

                                    {e.title}
                                </div>
                                {
                                  e.engName && <div>{e.engName}</div>
                                }
                                {
                                  e.customAttributes.length > 0 && <div>
                                    {e.customAttributes.map((e) => `${e.key}: ${e.value}`).join(',')}
                                  </div>
                                }
                                <div>
                                    {e.spec} x {e.count}
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <div>

                                        {`\$${e.price}`}
                                    </div>
                                </div>
                            </div>
                        </div>)
                    }
                </div>
                <div style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                }}>
                    <div>
                        <h5 style={{ marginTop: 16 }}>總計 ${checkout?.price || 0}</h5>
                    </div>
                    {
                        items.length > 0 && (
                            <div>
                                <CompactButton
                                    onClick={() => {
                                        window.location = checkout.webUrl;
                                    }
                                    }
                                >
                                    結帳
                                </CompactButton>
                                <div style={{height: 16}}></div>
                                <CompactButton
                                    onClick={() => {
                                        window.location = `https://map.ezship.com.tw/ezship_map_web_2014.jsp?webPara=${checkoutId}&rtURL=https://coffee-cvs.ggininderr.workers.dev`;
                                    }
                                    }
                                >
                                    超商取貨
                                </CompactButton>
                                <div style={{height: 16}}></div>
                            </div>
                        )
                    }
                    <CompactButton
                        onClick={() => {
                            navigate('/shop');
                        }
                        }
                    >
                        繼續購物
                    </CompactButton>
                </div>
            </div>
        </div>
    );
};

export default Cart;
